import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { getAddressStr } from './utils';
import currencyJson from './data/currency.json';

const AVAILABLE_TAXONOMY_IDS = [4, 8, 12];

export const loadingTaxonomiesSelector = (state) => state.account.loadingTaxonomies;

export const taxonomiesSelector = createSelector(
  (state) => state.account.taxonomies,
  (taxonomies) =>
    taxonomies
      ? _.chain(taxonomies)
          .filter((item) => _.includes(AVAILABLE_TAXONOMY_IDS, item.id))
          .sortBy('id')
          .map((item) => ({ ...item, route: `${item.abbr}/${item.revision}`.toLowerCase() }))
          .value()
      : undefined
);

export const loadingUserSettingsSelector = (state) => state.account.loadingUserSettings;

export const userSettingsSelector = (state) => state.account.userSettings;

export const themeSelector = () => 'grey-yellow';

export const industryClassificationSelector = createSelector(userSettingsSelector, (userSettings) => {
  const industryClassificationObj = _.find(userSettings, { key: 'industry_classification' }) || {};
  return industryClassificationObj.value || industryClassificationObj.default_value;
});

export const taxonomySelector = createSelector(
  taxonomiesSelector,
  industryClassificationSelector,
  (taxonomies, industryClassification) => {
    const taxonomyObj = _.find(taxonomies, { route: industryClassification });
    return taxonomyObj ? taxonomyObj.id : undefined;
  }
);

export const countrySelector = createSelector(userSettingsSelector, (userSettings) => {
  const countryObj = _.find(userSettings, { key: 'country_region' }) || {};
  return countryObj.value || countryObj.default_value;
});

export const countryCodeSelector = createSelector(countrySelector, (country) =>
  country === 'others' ? 'au' : country
);

export const currencySelector = createSelector(userSettingsSelector, (userSettings) => {
  const currencyObj = _.find(userSettings, { key: 'currency' }) || {};
  return currencyObj.value || currencyObj.default_value;
});

export const currencySymbolSelector = createSelector(currencySelector, (currency) => {
  const currencyObj = _.find(currencyJson, { code: currency });
  return currencyObj ? currencyObj.symbol_native : '$';
});

export const dateFormatSelector = createSelector(userSettingsSelector, (userSettings) => {
  const dateFormatObj = _.find(userSettings, { key: 'date_format' }) || {};
  return dateFormatObj.value || dateFormatObj.default_value;
});

export const loadingUserProfileSelector = (state) => state.account.loadingUserProfile;

export const userRolesSelector = createSelector(
  (state) => state.account.user,
  (user) => {
    if (user) {
      const { roles } = user;
      return roles;
    }
    return null;
  }
);

export const trialUserSelector = createSelector(userRolesSelector, (userRole) => {
  if (userRole) {
    return userRole.includes('Trial');
  }
  return undefined;
});

export const userProfileSelector = createSelector(
  (state) => state.account.userProfile,
  (userProfile) => {
    if (userProfile) {
      const { address } = userProfile;
      const addressStr = getAddressStr(address);
      return { ...userProfile, addressStr };
    }
    return undefined;
  }
);

export const userIdSelector = createSelector(userProfileSelector, (userProfile) =>
  userProfile ? userProfile.user_id : null
);

export const personNameSelector = createSelector(userProfileSelector, (userProfile) =>
  userProfile ? userProfile.person_name ?? '' : ''
);

export const loadingUserAvatarSelector = (state) => state.account.loadingUserAvatar;

export const userAvatarSelector = (state) => state.account.userAvatar;

export const loadingCompaniesSelector = (state) => state.account.loadingCompanies;

export const companiesSelector = createSelector(
  (state) => state.account.companies,
  (companies) => {
    if (companies) {
      return _.map(companies, (item) => {
        const { address } = item;
        const addressStr = getAddressStr(address);
        return { ...item, addressStr };
      });
    }
    return undefined;
  }
);

export const companyInfoSelector = createSelector(companiesSelector, (companies) => {
  if (companies && companies.length > 0) {
    return _.find(companies, { is_primary: false }) || companies[0];
  }
  return undefined;
});

export const companyPrimaryInfoSelector = createSelector(companiesSelector, (companies) => {
  if (companies && companies.length > 0) {
    return _.find(companies, { is_primary: true }) || companies[0];
  }
  return undefined;
});

export const companyPrimaryIdSelector = createSelector(companyPrimaryInfoSelector, (companyInfo) =>
  companyInfo ? companyInfo.id : null
);

export const companyNameSelector = createSelector(companyInfoSelector, (companyInfo) =>
  companyInfo ? companyInfo.name : ''
);

export const companyLogoSelector = createSelector(companyInfoSelector, (companyInfo) =>
  companyInfo ? companyInfo.logo : null
);

export const isUnderwriterSelector = createSelector(companiesSelector, (companies) => {
  const IAG_COMPANIES = [5235, 7740, 12383, 10197, 9703, 6393];
  return companies.every((company) => IAG_COMPANIES.includes(company.id));
});

export const loadingThemesSelector = (state) => state.account.loadingThemes;

const DEFAULT_THEME = { colorTitle: '#003262', colorBg1: '#2367a5', colorBg2: '#00203f', colorBar: '#019cae' };

export const themesSelector = createSelector(
  (state) => state.account.themes,
  userSettingsSelector,
  (themes = [], userSettings) => {
    const defaultTheme = { id: 'default', label: 'Default Theme', value: DEFAULT_THEME };
    const reportThemeObj = _.find(userSettings, { key: 'report_theme' }) || {};
    const defaultOptions =
      reportThemeObj && reportThemeObj.default_options ? JSON.parse(reportThemeObj.default_options) : [];
    return [
      defaultTheme,
      ..._.unionBy(defaultOptions, themes, 'id').map((item) => ({
        ...item,
        id: `${item.id}`,
        value: JSON.parse(item.value),
      })),
    ];
  }
);

export const reportThemeSelector = createSelector(
  userSettingsSelector,
  themesSelector,
  companyPrimaryIdSelector,
  (userSettings, themes, primaryCompanyId) => {
    const reportThemeObj = _.find(userSettings, { key: 'report_theme' });
    const MARSH_COMPANY_ID = [5770, 12617];
    if (MARSH_COMPANY_ID.includes(primaryCompanyId) && !reportThemeObj?.value) {
      return _.find(themes, (item) => item.label === 'Marsh theme');
    }
    return _.find(themes, (item) => `${item.id}` === `${reportThemeObj?.value}`) || themes[0];
  }
);

export const loadingSearchHistorySelector = (state) => state.account.loadingSearchHistory;

export const searchHistorySelector = (state) => state.account.searchHistory;

export const recentSearchSelector = createSelector(
  searchHistorySelector,
  dateFormatSelector,
  (searchHistory, dateFormat) =>
    searchHistory
      ? _.chain(searchHistory)
          .orderBy(['last_accessed_on'], ['desc'])
          .slice(0, 10)
          .map((item) => ({ ...item, date: moment(item.last_accessed_on).format(dateFormat) }))
          .value()
      : undefined
);

export const loadingUserReportsSelector = (state) => state.account.loadingUserReports;

export const userReportsSelector = (state) => state.account.userReports;

export const recentReportsSelector = createSelector(userReportsSelector, dateFormatSelector, (reports, dateFormat) =>
  reports
    ? _.chain(reports)
        .orderBy(['last_updated_on'], ['desc'])
        .slice(0, 10)
        .map((item) => ({
          ...item,
          date: moment(item.last_updated_on).format(dateFormat),
          rawDate: item.last_updated_on,
        }))
        .value()
    : undefined
);

export const ssoTokenSelector = createSelector(
  (state) => state.account.user,
  (user) => {
    if (user) {
      return user.sso_token ? atob(user.sso_token) : undefined;
    }
    return undefined;
  }
);

export const userNameSelector = (state) => state.account.user.user_name;

export const trialUserOptions = {
  industries: [
    {
      id: 15468,
      name: 'General Printing',
      code: '16110180',
    },
    {
      id: 21383,
      name: 'Mattress Retailing',
      code: '42110045',
    },
    {
      id: 21991,
      name: 'Restaurant Operation',
      code: '45110070',
    },
  ],
  courses: [
    {
      description:
        'This short course is intended to provide the user with a general understanding of the construction and use of sandwich paneling and the risks associated with its use.This course includes a multiple choice questionnaire at the end.',
      id: 25,
      last_update: '12/7/2011',
      name: 'Sandwich Panel Products',
    },
  ],
  documents_assessments: ['1', '2', '3', '4'],
  allowedEndPoints: [
    '/classifications/anzsic/2006',
    '/courses/25',
    '/documents/assessments/003.00000',
    '/documents/assessments/001.15468',
    '/documents/assessments/001.21991',
    '/documents/assessments/001.21383',
    '/documents/insights/au.15468',
    '/documents/insights/au.21991',
    '/documents/insights/au.21383',
    '/industries/15468',
    '/industries/21991',
    '/industries/21383',
    '/insurance_classes',
    '/taxonomies',
    '/user',
  ],
};
