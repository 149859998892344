import React from 'react';
import { Page } from '../../../../../../../../components/Print/Print';
import { ThemeContext } from '../../../../../../ReportContext';
import useTheme from '../../../../hooks/useTheme';
import './Disclaimer.scss';

export default () => {
  const theme = useTheme(ThemeContext);

  return (
    <Page>
      <div className="report-preview-aon-disclaimer">
        <div
          className="report-preview-disclaimer-content"
          style={{
            fontFamily: theme.body.font,
            fontSize: theme.body.size,
            color: theme.body.color,
          }}
        >
          <h2
            style={{
              fontFamily: theme.headings.h2.font,
              fontSize: theme.headings.h2.size,
              color: theme.headings.h2.color,
            }}
          >
            Usage Notice
          </h2>
          <p>
            The materials contained within this report constitute general information only and should be considered with
            personal financial advice from an Australian Financial Services license holder. It is highly recommended
            that you speak to an insurance professional when setting your insurance program as each policy should be
            tailored to your specific needs.
          </p>
          <p>
            The information contained within the document is the property of The LMI Group and is intended for the
            addressee only. The contents of this document are not to be reproduced, modified or disclosed by its
            recipient without the express permission of the LMI Group. For a copy of our full terms of use, please visit
            our website:{' '}
            <a
              href="https://www.lmigroup.com"
              style={{ color: theme.link.color }}
              target="_blank"
              rel="noopener noreferrer"
            >
              www.lmigroup.com
            </a>{' '}
            AFS License No: 283777.
          </p>
        </div>
      </div>
    </Page>
  );
};
