import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Layout from '../../components/Layout/Layout';
import IndustrySearch from './components/IndustrySearch/IndustrySearch';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import RecentActivities from './components/RecentActivities/RecentActivities';
import Ad from './components/Ad/Ad';
import UserProfile from './components/UserProfile/UserProfile';
import KnowledgeCard from './components/KnowledgeCard/KnowledgeCard';
import Feedback from './components/Feedback/Feedback';
import TailoredReport from './components/TailoredReport/TailoredReport';
import { PointCardAnziif, PointCardNiba } from '../Point/components/Card/Card';
import { personNameSelector } from '../Account/AccountSelectors';
import { pointsSelector } from '../Point/PointSelectors';
import { CountryContext } from '../../contexts';
import './Dashboard.scss';

const renderGreet = (name) => {
  const date = new Date();
  const hrs = date.getHours();
  let greet = 'Good Evening';
  if (hrs < 12) {
    greet = 'Good Morning';
  } else if (hrs < 18) {
    greet = 'Good Afternoon';
  }
  return `${greet}, ${name.split('.')[0]}`;
};

const Dashboard = ({ history, personName, points = {} }) => (
  <CountryContext.Consumer>
    {({ country }) => {
      const UK_LAYOUT = country === 'gb';

      return (
        <Layout showMenu>
          <div className="dashboard component-wrapper">
            <div className="dashboard-greet">
              <h1>{`${renderGreet(personName)}`}</h1>
            </div>
            <Row gutter={20} type="flex">
              <Col xs={24} lg={16} className="dashboard-item">
                <IndustrySearch />
              </Col>
              <Col xs={24} lg={8} className="dashboard-item">
                {UK_LAYOUT ? <TailoredReport /> : <GeneralInfo />}
              </Col>
              {!UK_LAYOUT && (
                <Col xs={24} lg={6}>
                  <Row gutter={20} type="flex" style={{ height: '100%' }}>
                    <Col xs={24} sm={12} lg={24} className="dashboard-item">
                      <PointCardAnziif point={points.cip} onClick={() => history.push('/points?type=cip')} />
                    </Col>
                    <Col xs={24} sm={12} lg={24} className="dashboard-item">
                      <PointCardNiba point={points.cpd} onClick={() => history.push('/points?type=cpd')} />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} sm={12} md={14} lg={UK_LAYOUT ? 14 : 10} xl={UK_LAYOUT ? 16 : 12} className="dashboard-item">
                <RecentActivities />
              </Col>
              <Col xs={24} sm={12} md={10} lg={UK_LAYOUT ? 10 : 8} xl={UK_LAYOUT ? 8 : 6} className="dashboard-item">
                <Ad country={country} />
              </Col>
              <Col xs={24} md={12} lg={8} className="dashboard-item">
                <UserProfile />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <KnowledgeCard country={country} />
              </Col>
              <Col xs={24} lg={8} className="dashboard-item">
                <Feedback />
              </Col>
            </Row>
          </div>
        </Layout>
      );
    }}
  </CountryContext.Consumer>
);

const mapStateToProps = (state) => ({
  personName: personNameSelector(state),
  points: pointsSelector(state),
});

export default connect(mapStateToProps)(Dashboard);
