import { ReactComponent as propertyDamage } from './property-damage.svg';
import { ReactComponent as businessInterruption } from './business-interruption.svg';
import { ReactComponent as crime } from './crime.svg';
import { ReactComponent as marine } from './marine.svg';
import { ReactComponent as motor } from './motor.svg';
import { ReactComponent as publicLiability } from './public-liability.svg';
import { ReactComponent as productLiability } from './product-liability.svg';
import { ReactComponent as professionalIndemnity } from './professional-indemnity.svg';
import { ReactComponent as managementLiability } from './management-liability.svg';
import { ReactComponent as employerLiability } from './employer-liability.svg';
import { ReactComponent as equipmentBreakdown } from './equipment-breakdown.svg';
import { ReactComponent as cyber } from './cyber.svg';
import { ReactComponent as corporateTravel } from './corporate-travel.svg';
import { ReactComponent as productRecall } from './product-recall.svg';
import { ReactComponent as commercialLines } from './commercial-lines.svg';
import { ReactComponent as personalLines } from './personal-lines.svg';
import { ReactComponent as homeContents } from './home-and-contents.svg';

export default {
  property_damage: propertyDamage,
  business_interruption: businessInterruption,
  crime,
  marine_including_inland_transit: marine,
  motor,
  public_liability: publicLiability,
  product_liability: productLiability,
  professional_indemnity: professionalIndemnity,
  management_liability: managementLiability,
  employer_liability_workers_compensation: employerLiability,
  equipment_breakdown: equipmentBreakdown,
  machinery_and_engineering: equipmentBreakdown,
  cyber_risks: cyber,
  corporate_travel: corporateTravel,
  product_recall_product_contamination: productRecall,
  commercial_lines: commercialLines,
  personal_lines: personalLines,
  home_contents: homeContents,
};
