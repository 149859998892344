import React from 'react';
import SearchBox from '../../../Industry/components/SearchBox/SearchBox';
import './IndustrySearch.scss';

export default () => (
  <div className="industry-search card">
    <div className="industry-search-intro">
      <h1>INDUSTRY SPECIFIC INFORMATION</h1>
      <p className="industry-search-description">
        Access the Industry Specific information, the information is broken down to specific classes of insurance but
        takes it one step further by focusing on the specific risks and exposures that are likely to be found in the
        selected industry, this information is supported with photographs, risk checklists and a list of the significant
        exposures.
      </p>
      <h2>
        more than <span>7,000</span> occupations and activities
      </h2>
    </div>
    <div className="industry-search-area">
      <h1>Industry Search</h1>
      <p>Type a keyword or ANZSIC code or browse by category or name.</p>
      <SearchBox size="large" mode="vertical" />
    </div>
  </div>
);
